<template>
  <div class="message-main">
    <!-- 右侧信息区 -->
    <div class="box2" v-if="newList.length">
      <div
        class="group"
        v-for="(item, index) in newList"
        :key="index"
        @click="
          $router.push({
            path: '/index-newsDetail?id=' + item.id,
          })
        "
      >
        <img class="information" :src="item.images" v-if="item.images" />
        <!-- <img class="information" src="@image/xinxi.jpg" v-else /> -->
        <div class="right">
          <span class="title">{{ item.title }}</span>
          <span class="time">{{ item.create_time }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newList: [],
    };
  },
  mounted() {
    this.getIndexData();
  },
  methods: {
    async getIndexData() {
      //新闻列表
      let { code, data, msg } = await this.$newsApi.getNewsBrowse();
      if (code == 200) {
        this.newList = data;
      } else {
        this.$message.error(msg);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.message-main {
  > .box2 {
    display: flex;
    flex-direction: column;
    width: 294px;
    background: #f2f2f2;
    padding: 0 15px;
    box-sizing: border-box;
    > .group {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      margin-bottom: 15px;
      padding-bottom: 15px;

      &:not(:last-child) {
        border-bottom: 1px dashed #000;
      }

      &:first-child {
        margin-top: 15px;
      }

      > .information {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 10px;
        margin-right: 10px;
      }
      > .right {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        > .title {
          color: #000;
          line-height: 20px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          -webkit-box-orient: vertical;
          height: 58px;
          margin-bottom: 10px;
        }
        > .time {
          color: #8f8f8f;
          margin-top: auto;
        }
      }
    }
  }
}
</style>
