<template>
  <div class="page" v-loading="loading">
    <div class="investigate-box">
      <div class="left">
        <!-- banner -->
        <div class="left-rule">
          <img class="banner" src="@image/diaoyan-banner.png" />
          <span class="title">调研获得收益</span>
          <div class="money">
            <span class="symbol">￥</span>
            <span class="number">{{ total_revenue }}</span>
          </div>
          <div class="group">
            <span class="quantity">参与项目数量：</span>
            <span class="number">{{ finish_num }}</span>
          </div>
          <span class="button" style="cursor: pointer" @click="goRule"
            >调研规则</span
          >
        </div>

        <!-- 调研 -->
        <div class="min400">
          <div v-for="(item, index) in projectList" :key="index">
            <a
              target="_blank"
              :href="item.partner_link || 'javascript:return false;'"
            >
              <div class="left-onLine left-onLine-text">
                <div class="img">
                  <img class="banner2" :src="item.pic_url" />
                  <div class="mark" :style="item.pro_type | typeColor">
                    <span>{{ item.pro_type | typeName }}</span>
                  </div>
                </div>
                <div class="left-onLine-right">
                  <span
                    :class="
                      item.isGray && item.isGray == 1
                        ? 'title grayText'
                        : 'title'
                    "
                    >{{ item.project_id }}</span
                  >
                  <span
                    :class="
                      item.isGray && item.isGray == 1
                        ? 'subTitle grayText'
                        : 'subTitle'
                    "
                    >{{ item.pro_name }}</span
                  >
                  <div class="right-button">
                    <span
                      :class="
                        item.isGray && item.isGray == 1 ? 'blue grayBG' : 'blue'
                      "
                      >{{ item.min_time_limit }}分钟</span
                    >
                    <span
                      :class="
                        item.isGray && item.isGray == 1 ? 'blue grayBG' : 'blue'
                      "
                      >¥{{ item.point }}</span
                    >
                    <!-- <span class="blue">{{item.duration}}分钟</span>
									<span class="blue">¥{{item.cash_gift}}</span> -->
                    <!-- <span @click.stop="goDetail(item, 2)" class="blue"
                    >我要推荐</span
                  > -->
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>

        <!-- 分页 -->
        <div class="paging">
          <el-pagination
            hide-on-single-page
            @current-change="handleCurrentChange"
            :current-page.sync="page"
            :page-size="limit"
            layout="prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>

        <!-- 暂无数据 -->
        <div v-if="!projectList.length && !loading">
          <div class="null-box">
            <img
              :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
              alt=""
            />
            <div class="title">暂无数据</div>
          </div>
        </div>
      </div>
      <!-- 右侧个人信息区 -->
      <div class="right">
        <div class="box">
          <div class="bottom">
            <img class="headPortrait" :src="userInfoAvatar" />
            <span>{{ userInfo.nickname ? userInfo.nickname : "请登录" }}</span>
            <div class="group">
              <div class="left" @click="xin.goUrl('/userCenter?index=4')">
                <span>{{ userParam.follow || 0 }}</span>
                <span class="subTitle">粉丝</span>
              </div>
              <div class="line"></div>
              <div class="left" @click="xin.goUrl('/userCenter?index=5')">
                <span>{{ userParam.attention || 0 }}</span>
                <span class="subTitle">关注</span>
              </div>
            </div>
            <!-- <img @click="$router.push({path: '/found-release-index'})"  class="button" src="@image/fabudiaoyan.png" /> -->
          </div>
        </div>
        <!-- 右侧信息区 -->
        <messageTwo></messageTwo>
      </div>
    </div>
  </div>
</template>

<script>
import messageTwo from "@components/message/message";
import { mapState } from "vuex";

export default {
  components: {
    messageTwo,
  },
  computed: {
    ...mapState("userManagement", ["userInfo", "userParam"]),
    userInfoAvatar() {
      return this.userInfo.avatar
        ? this.userInfo.avatar
        : this.$http.baseURL + "/static/images/web/1.png";
    },
  },
  data() {
    return {
      loading: false,
      idsList: [], //已参与调研活动id
      grayList: [], //已参与调研活动
      isMore: 0,
      list: "",
      info: {
        // 用户金币
        price: 0,
        num: 0,
      },
      page: 1,
      allPage: 1,
      limit: 10,
      title: "",
      total: 0,
      projectList: [],
      finish_num: 0,
      total_revenue: 0,
    };
  },

  filters: {
    // 问卷类型
    typeName(val) {
      switch (Number(val)) {
        case 1:
          val = "在线调研";
          break;
        case 2:
          val = "电话调研";
          break;
        case 3:
          val = "电话回访";
          break;
        case 4:
          val = "面访调研";
          break;
        case 5:
          val = "小组座谈";
          break;
        case 6:
          val = "精准推送";
          break;
        default:
          val = "在线调研";
          break;
      }
      return val;
    },

    // 问卷类型颜色
    typeColor(val) {
      switch (Number(val)) {
        case 1:
          val = "background:#F0CB2D";
          break;
        case 2:
          val = "background:#E60012";
          break;
        case 3:
          val = "background:#1DBAFF";
          break;
        case 4:
          val = "background:#03DBC6";
          break;
        case 5:
          val = "background:#0A56BE";
          break;
        case 6:
          val = "background:#971FFD";
          break;
        default:
          val = "background:#F0CB2D";
          break;
      }
      return val;
    },
  },

  async mounted() {
    // window.addEventListener('scroll',this.scrollHandle);//绑定页面滚动事件
    // this.xin.isLoadding(true)
    let token = localStorage.getItem("token") || "";
    if (token) {
      // this.getPrice();
    }
    await this.getIds();
    // await this.getList();
    await this.survyProject();
    // this.xin.isLoadding()
  },

  beforeDestroy() {
    //在组件生命周期结束的时候销毁。
    window.removeEventListener("scroll", this.scrollhandle);
  },

  methods: {
    // 调研项目问卷列表
    async survyProject() {
      this.loading = true;
      let token = localStorage.getItem("token");
      let { code, data } = await this.$researchApi.survyProject({
        token,
        type: 4,
      });
      this.loading = false;
      if (code == 200) {
        this.projectList = data.data;
        this.finish_num = data.finish_num || 0;
        this.total_revenue = data.total_revenue || 0;
        // this.total = data.data.length;
      }
    },

    // 分页
    handleCurrentChange(val) {
      this.page = val;
      // this.getList();
      this.survyProject();
    },

    scrollHandle(e) {
      let all = document.body.scrollHeight;
      let top = e.srcElement.scrollingElement.scrollTop; // 获取页面滚动高度
      if (top > all - 1200 && this.isMore == 0) {
        this.isMore = 1;
        this.togetList();
      }
    },

    togetList() {
      if (this.page < this.allPage) {
        this.page = this.page + 1;
        this.survyProject();
      } else {
        this.isMore = 2;
      }
    },

    // 调研规则
    goRule() {
      this.xin.goUrl("/research-questionnaire-informedRule");
    },

    // 调研id
    async getIds() {
      if (!localStorage.getItem("token")) return;
      let res = await this.$researchApi.checkQuestions();
      if (res.code == 200) {
        this.idsList = res.data || [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

a {
  text-decoration: none;
}
.min400 {
  min-height: 0;
}
.left-onLine-right,
.interview-bottom {
  title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }
  .subTitle {
    display: -webkit-box;
    line-height: 24px;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }
}
.page {
  > .investigate-box {
    display: flex;
    flex-direction: row;
    width: 1200px;
    margin: 0 auto;
    margin-top: 30px;
    > .left {
      display: flex;
      flex-direction: column;
      .left-rule {
        position: relative;
        margin-bottom: 30px;
        color: #ffffff;
        > .title {
          position: absolute;
          font-size: 24px;
          left: 75px;
          top: 49px;
        }
        > .money {
          display: flex;
          flex-direction: row;
          position: absolute;
          left: 90px;
          top: 90px;
          > .number {
            font-size: 40px;
          }
          > .symbol {
            margin-top: auto;
          }
        }
        > .group {
          position: absolute;
          display: flex;
          flex-direction: row;
          font-size: 24px;
          left: 75px;
          top: 193px;
        }
        > .button {
          position: absolute;
          color: #7657f1;
          font-size: 20px;
          text-align: center;
          line-height: 40px;
          width: 130px;
          height: 44px;
          background: #ffffff;
          border-radius: 5px;
          box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
          left: 459px;
          top: 133px;
        }
        > .banner {
          width: 890px;
          height: 260px;
        }
      }
      .left-onLine-text {
        flex-direction: row-reverse !important;
      }
      .left-onLine {
        cursor: pointer;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        overflow: hidden;
        padding-bottom: 30px;
        box-sizing: border-box;
        border-bottom: 1px solid #f2f2f2;
        margin-bottom: 30px;
        > .img {
          width: 150px;
          height: 150px;
          overflow: hidden;
          > .banner2 {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          > .mark {
            background: #f6e06a;
            width: 120px;
            height: 24px;
            transform: rotate(307deg); //旋转
            position: absolute;
            text-align: center;
            left: -35px;
            top: 25px;
            line-height: 24px;
            color: #ffffff;
            font-size: 12px;
          }
        }

        > .left-onLine-right {
          width: calc(100% - 150px - 30px);
          // flex-direction: row-reverse;
          display: flex;
          flex-direction: column;
          color: #333333;
          font-size: 24px;
          > .subTitle {
            font-size: 18px;
            color: #8f8f8f;
            margin-top: 10px;
          }
          > .right-button {
            display: flex;
            flex-direction: row;
            font-size: 18px;
            color: #ffffff;
            margin-top: auto;
            > .blue {
              cursor: pointer;
              &:nth-child(2) {
                width: 96px;
                margin-left: 30px;
              }
              // &:last-child {
              //   width: 132px;
              //   background: #f6e06a;
              //   color: #333333;
              //   margin-left: 342px;
              // }
              width: 110px;
              height: 44px;
              background: linear-gradient(90deg, $--themeColor, #46cbca);
              border-radius: 5px;
              text-align: center;
              line-height: 44px;
            }
          }
        }
      }
      .left-interview {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        padding-bottom: 30px;
        box-sizing: border-box;
        border-bottom: 1px solid #f2f2f2;
        margin-bottom: 30px;
        > .interview-mark {
          background: $--themeColor;
          width: 120px;
          height: 24px;
          transform: rotate(307deg); //旋转
          position: absolute;
          text-align: center;
          left: -35px;
          top: 25px;
          line-height: 24px;
          color: #ffffff;
          font-size: 12px;
        }
        > .banner3 {
          width: 890px;
          height: 200px;
          object-fit: cover;
        }
        > .interview-bottom {
          display: flex;
          flex-direction: column;
          color: #333333;
          font-size: 24px;
          margin-top: 20px;
          > .subTitle {
            font-size: 18px;
            color: #8f8f8f;
            margin-top: 10px;
          }
          > .bottom-button {
            display: flex;
            flex-direction: row;
            font-size: 18px;
            color: #ffffff;
            margin-top: 30px;

            > .blue {
              cursor: pointer;
              &:nth-child(2) {
                width: 96px;
                margin-left: 30px;
              }
              &:last-child {
                width: 132px;
                background: #f6e06a;
                color: #333333;
                margin-left: auto;
              }
              width: 110px;
              height: 44px;
              background: linear-gradient(90deg, $--themeColor, #46cbca);
              border-radius: 5px;
              text-align: center;
              line-height: 40px;
            }
          }
        }
      }
    }
    > .right {
      display: flex;
      flex-direction: column;
      margin-left: 16px;
      > .box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 294px;
        background: #f2f2f2;
        padding: 15px;
        box-sizing: border-box;
        margin-bottom: 20px;
        > .bottom {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 264px;
          background: #ffffff;
          font-size: 14px;
          color: #333333;
          border-radius: 5px;
          padding: 20px 22px;
          box-sizing: border-box;
          font-size: 20px;
          > .headPortrait {
            width: 60px;
            height: 60px;
            object-fit: cover;
            margin-bottom: 10px;
            border-radius: 50%;
          }
          > .group {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 20px;
            > .left {
              cursor: pointer;
              display: flex;
              flex-direction: column;
              align-items: center;
              > .subTitle {
                color: #8f8f8f;
                font-size: 14px;
                margin-top: 8px;
              }
            }
            > .line {
              width: 2px;
              height: 30px;
              background: #f2f2f2;
              margin: 0 50px;
            }
          }
          > .button {
            width: 220px;
            height: 44px;
            object-fit: cover;
            margin-top: 10px;
            cursor: pointer;
          }
        }
      }
      > .box2 {
        display: flex;
        flex-direction: column;
        width: 294px;
        background: #f2f2f2;
        padding: 0 15px;
        box-sizing: border-box;
        > .group {
          &:first-child {
            margin-top: 15px;
          }
          display: flex;
          flex-direction: row;
          margin-bottom: 15px;
          > .information {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 10px;
            margin-right: 10px;
          }
          > .right {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            > .title {
              color: #333333;
              line-height: 20px;
            }
            > .time {
              color: #8f8f8f;
              margin-top: auto;
            }
          }
        }
      }
    }
  }
}
.moreTips {
  text-align: center;
}
.grayText {
  color: #999999;
}
.grayBG {
  background: #999999 !important;
}
</style>
